import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmA from "src/components/nonbmA_bf"
import CtaDisclosureCb2 from "src/components/ctadisclosurecb2"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"

const prdbestData = import("./data/data.json");

export default function nonbmclearbar() {

  let css;
  let module = <CtaDisclosureCb2></CtaDisclosureCb2>;
  if(isChrome() || isIeEdge() || isFirefox()){
    module = <CtaDisclosureCb2></CtaDisclosureCb2>;
  }
  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
  background:#fff;
}

.nonbma-bf-module--nonbmaBf .nonbma-bf-module--aboveFold {
	background: url("/img/abstract-bg.png") no-repeat;
  background-size:cover;
}
.nonbma-bf-module--nonbmaBf .nonbma-bf-module--bf {
box-shadow: inset 0 7px 36px -7px rgba(0,0,0,0.2);
}

button.trbm_cta {
  font-weight: normal;
  font-size: 30px;
}

button.install {
  color: #fff;
  background: #FC6422;
  padding: 20px 100px;
  font-size: 35px;
  font-weight: normal;
  margin: 32px auto;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: .2s ease;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
  box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.4);
  display: block;
  font-weight:700;
}

button.install:hover {
  transform: scale(1.1);
}

.trbm-module--bottom h2,
.trbm-module--howto span, .footerlinks-module--br, .footerlinks-module--br a {
  color: #f1f1f1;
}

#ctadisclosure-module--ctadisclaimer {
  padding: 0px 7%;
  padding-bottom: 0;
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

#ctadisclosurecb2-module--ctadisclaimer {
  font-weight: normal;
  color:#000000;
}

a:hover {
  text-decoration:none;
}

a.ctadisclosurecb2-module--bold {
  color:#000000;dd3-abs-spc
  font-weight:normal;
}

.nonbma-bf-module--nonbmaBf .nonbma-bf-module--center {
  -webkit-box-shadow: 0px 3px 7px 3px rgba(0,0,0,0.3); 
  box-shadow: 0px 3px 7px 3px rgba(0,0,0,0.3);
}

.nonbma-module--nonbma .nonbma-module--center h3 {
  width: 100%;
  max-width: 700px;
  min-width: 600px;
  line-height: 1.5;
  font-size: 18pt;
  color: #333;
  margin: 12px auto 0;
}

.nonbma-module--nonbma ul.nonbma-module--bullets {
  display: none;
}

.nonbma-module--nonbma .nonbma-module--install h2 {
  margin: 20px auto;
}

.nonbma-module--nonbma .nonbma-module--install {
  padding: 5px 0 10px;
}
`}
   </style>

   <style type="text/css">
        {css}
            </style>

    <title>Easy Print - Easily Print Any Page!</title></Helmet>
      <NonbmA data={prdbestData}>{module}</NonbmA>
    </HomepageLayout>
  )
}
